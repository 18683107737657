import capio from "./capioTheme";
import doktorse from "./doktorseTheme";
import ptj from "./ptjTheme";
import meliva from "./melivaTheme";
import wetterhalsan from "./wetterhalsanTheme";
import defaultTheme from "./defaultTheme";

export const getTheme = () => {
  const theme = window.REACT_APP_THEME;
  switch (theme.trim()) {
    case "capio":
      return capio;
    case "ptj":
      return ptj;
    case "doktorse":
      return doktorse;
    case "meliva":
      return meliva;
    case "wetterhalsan":
      return wetterhalsan;
    default:
      return defaultTheme;
  }
};
