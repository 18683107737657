import { collabodocTheme } from "@collabodoc/component-library";
import logo from "../images/Logo/collabodoc_light.png";

const defaultTheme = {
  ...collabodocTheme,
  logo: logo,
  productCard: {
    backgroundColor: collabodocTheme.colors.tertiary,
    boxShadow: "0px 5px 15px #d1d1d1",
    disabled: {
      color: collabodocTheme.colors.primary,
      backgroundColor: collabodocTheme.colors.disabled,
    },
    border: {
      color: collabodocTheme.colors.primary,
    },
    boxShadowColor: collabodocTheme.colors.primary,
    color: collabodocTheme.colors.primary,
    hover: {
      color: collabodocTheme.colors.secondary,
      backgroundColor: collabodocTheme.colors.primary,
      border: {
        color: "#09385c",
      },
    },
  },
};

export default defaultTheme;
