import { OidcSecure, useOidc } from "@axa-fr/react-oidc";
import { NavBar } from "@collabodoc/component-library";
import React, { useContext, useEffect, useState } from "react";
import { IntlProvider, useIntl } from "react-intl";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Container } from "reactstrap";
import { ThemeContext, ThemeProvider } from "styled-components";
import { getManuals } from "./Blob";
import FontProvider from "./fonts/fonts";
import Footer from "./Footer";
import ProductsView from "./ProductsView";
import { getTheme } from "./themes/getTheme";
import { EXTERNAL_URLS } from "./urls";
import UserManuals from "./UserManuals";
import { getTranslations, LOCALE } from "./utils/localeUtils";
import { Auth } from "./Auth";
import {
  CareCenterContext,
  CareCenterContextProvider,
} from "./CareCenterContext";

const config = {
  authority: `${window.REACT_APP_USERSERVICE_URL}`,
  client_id: window.REACT_APP_OIDC_CLIENT_ID,
  monitor_session: true,
  redirect_uri: `${window.location.origin}/auth/signed-in`,
  silent_redirect_uri: `${window.location.origin}/auth/signed-in-silent`,
  scope: `${process.env.REACT_APP_OIDC_SCOPE}`,
  service_worker_only: false,
  service_worker_relative_url: "/OidcServiceWorker.js",
};

const App = () => {
  const [manuals, setManuals] = useState(null);

  useEffect(() => {
    getManuals().then((result) => setManuals(result));
  }, []);

  const theme = getTheme();

  return (
    <IntlProvider locale={LOCALE} messages={getTranslations()}>
      <ThemeProvider theme={theme}>
        <FontProvider />
        <Auth config={config}>
          <OidcSecure>
            <CareCenterContextProvider>
              <BrowserRouter>
                <Switch>
                  <React.Fragment>
                    <Container>
                      <NavBarContainer />
                      <Route exact path={"/"}>
                        <ProductsView />
                        <Footer />
                      </Route>
                      <Route path={"/usermanuals"}>
                        <UserManuals manuals={manuals}></UserManuals>
                      </Route>
                    </Container>
                    <Redirect to={"/"} />
                  </React.Fragment>
                </Switch>
              </BrowserRouter>
            </CareCenterContextProvider>
          </OidcSecure>
        </Auth>
      </ThemeProvider>
    </IntlProvider>
  );
};

const NavBarContainer = () => {
  const { careCenters, setCurrentCareCenter, currentCareCenterId } =
    useContext(CareCenterContext);

  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const { logout } = useOidc();

  const handleChange = (id) => {
    setCurrentCareCenter(id);
    history.push(pathname);
  };

  let dropdown = null;
  if (careCenters && careCenters.length > 1 && currentCareCenterId) {
    dropdown = {
      selected: careCenters.find((cc) => cc.id === currentCareCenterId).name,
      dropdownitems: careCenters.map((cc) => ({
        label: cc.name,
        id: cc.id,
        handler: () => handleChange(cc.id),
      })),
    };
  }

  const navbarItems = [
    {
      label: intl.formatMessage({ id: "navbar.myaccount" }),
      url: `${EXTERNAL_URLS.MANAGE}?returnUrl=${window.location.href}`,
    },
    {
      label: intl.formatMessage({ id: "navbar.logout" }),
      handler: logout,
    },
  ];
  return <NavBar logo={theme.logo} items={navbarItems} dropdown={dropdown} />;
};
export default App;
