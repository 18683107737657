import { OidcProvider } from "@axa-fr/react-oidc";
import React from "react";
import { LoadingView } from "@collabodoc/component-library";
import PropTypes from "prop-types";

const Auth = ({ children, config }) => {
  return (
    <OidcProvider
      configuration={config}
      authenticatingComponent={LoadingView}
      callbackSuccessComponent={LoadingView}
      sessionLostComponent={SessionLost}
      loadingComponent={LoadingView}
    >
      {children}
    </OidcProvider>
  );
};

const SessionLost = () => {
  return (
    <div>
      <h1 className="textCenter marginTop50">Du har blivit utloggad</h1>
      <button
        onClick={() => (window.location.href = "/")}
        className="btn btn-primary "
      >
        Gå till startsidan
      </button>
    </div>
  );
};

const Callback = () => (
  <div>
    <LoadingView />
  </div>
);

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};
export { Auth };
