import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { API_URLS } from "./urls";
import psl from "psl";

const COOKIE = { CARE_CENTER_COOKIE: "CareCenterCookie" };

const parsed = psl.parse(window.location.hostname);
const domain = parsed.domain ? parsed.domain : parsed.input;

const CareCenterContext = createContext({});

const getCookieName = (siteName) => {
  return `${siteName}-${COOKIE.CARE_CENTER_COOKIE}`;
};

const getCookie = (siteName) => {
  const cookieName = getCookieName(siteName);
  const cookie = Cookies.get(cookieName);
  const decodedCookie = cookie && window.atob(cookie);
  if (!decodedCookie) return null;
  return JSON.parse(decodedCookie);
};

const setCookie = (userId, careCenterId, siteName) => {
  const cookieObject = {
    userId,
    careCenterId,
  };
  const serializedCookieObject = JSON.stringify(cookieObject);
  const encodedCookieObject = window.btoa(serializedCookieObject);
  const cookieName = getCookieName(siteName);
  Cookies.set(cookieName, encodedCookieObject, {
    domain: domain,
    expires: 30,
  });
};

const getCareCenterIdsFromUser = (user) => {
  if (!user || !user.carecenter) {
    return [];
  }
  //  If user belongs to multiple carecenters this is a array
  if (Array.isArray(user.carecenter)) {
    return user.carecenter.map((id) => parseInt(id));
  }
  //  Only one carecenter so type is string
  return [parseInt(user.carecenter)];
};

const getCareCenterIdFromCookie = (user, siteName) => {
  const cookie = getCookie(siteName);
  if (!user) return null;
  if (!cookie) return getCareCenterIdsFromUser(user)[0];
  if (cookie.userId === user?.sub) {
    const careCenterId = parseInt(cookie.careCenterId);
    if (getCareCenterIdsFromUser(user).find((id) => id === careCenterId)) {
      return careCenterId;
    }
  }
  Cookies.remove(getCookieName(siteName), { domain: domain });
  return getCareCenterIdsFromUser(user)[0];
};

const CareCenterContextProvider = ({ children }) => {
  const { oidcUser } = useOidcUser();
  const siteName = window.REACT_APP_CAREGIVER;
  const apiUrl = API_URLS.GET_CARECENTERS;

  const [currentCareCenterId, setCurrentCareCenterId] = useState(null);

  useEffect(() => {
    setCurrentCareCenterId(getCareCenterIdFromCookie(oidcUser, siteName));
  }, [oidcUser]);

  const { accessToken } = useOidcAccessToken();

  useEffect(() => {
    if (oidcUser && currentCareCenterId) {
      setCookie(oidcUser.sub, currentCareCenterId, siteName);
    }
  }, [oidcUser, currentCareCenterId, siteName]);

  const {
    data: careCenters = [],
    isLoading: isLoadingCareCenters,
    doFetch: reFetchCareCenters,
  } = useFetchy(apiUrl, getRequestInit({ accessToken: accessToken || "" }));

  const userCareCenters = useMemo(() => {
    const userCareCenterIds = getCareCenterIdsFromUser(oidcUser);
    return careCenters.filter((careCenter) =>
      userCareCenterIds.includes(careCenter.id)
    );
  }, [oidcUser, careCenters]);

  const currentCareCenter = useMemo(
    () =>
      userCareCenters.find(
        (careCenter) => careCenter.id === currentCareCenterId
      ),
    [userCareCenters, currentCareCenterId]
  );

  const setCareCenter = useCallback(
    (careCenterId) => {
      const userHasCareCenter = userCareCenters.find(
        (userCareCenter) => userCareCenter.id === careCenterId
      );
      if (!userHasCareCenter) {
        return;
      }
      setCurrentCareCenterId(careCenterId);
    },
    [userCareCenters]
  );

  const removeCareCenter = useCallback(() => {
    Cookies.remove(getCookieName(siteName), { domain: domain });
    setCurrentCareCenterId(null);
  }, []);

  return (
    <CareCenterContext.Provider
      value={{
        careCenters: userCareCenters,
        currentCareCenter: currentCareCenter,
        currentCareCenterId: currentCareCenterId,
        isLoadingCareCenters: isLoadingCareCenters,
        setCurrentCareCenter: setCareCenter,
        removeCurrentCareCenter: removeCareCenter,
        reFetchCareCenters: reFetchCareCenters,
      }}
    >
      {children}
    </CareCenterContext.Provider>
  );
};

export { CareCenterContext, CareCenterContextProvider };
